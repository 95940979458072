import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as eventSearchActions from '../../redux/actions/cryptoSearchActions';
import { CryptoDto } from '../../types/reactOutput/CryptoDto';
import { CryptoHistoricalPriceDto } from '../../types/reactOutput/CryptoHistoricalPriceDto';
import CryptoDetailsCard from './CryptoDetailsCard';
import CryptoSearchForm from './CryptoSearchForm';
import CryptoSearchResultsTable from './CryptoSearchResultsTable';
import { LoginUserResult } from '../../types/reactOutput/LoginUserResult';
import { CryptoAnalysisDto } from '../../types/reactOutput/CryptoAnalysisDto';
import { CryptoNewsDto } from '../../types/reactOutput/CryptoNewsDto';
import { TweetDto } from '../../types/reactOutput/TweetDto';

const CryptoLandingPage = ({
    cryptoSearchResults,
    cryptoHistoricalPrices,
    loginUserResult,
    eventFavorites,
    cryptoTweets,
    cryptoDetails,
    cryptoAnalysis,
    cryptoNews,
    actions }: { cryptoSearchResults: CryptoDto[], cryptoHistoricalPrices: CryptoHistoricalPriceDto[], 
        loginUserResult: LoginUserResult, eventFavorites: CryptoDto[], cryptoTweets: TweetDto[]
        cryptoDetails: CryptoDto, cryptoAnalysis: CryptoAnalysisDto, cryptoNews: CryptoNewsDto[], actions: any }) => {

    useEffect(() => {
        if (!cryptoSearchResults) {
            actions.executeLoadCrypto()
                .catch((error: any) => {
                    console.log("Error loading crypto data: " + error)
                });
        }
    }, [cryptoSearchResults])
    
    const onSearchEventsClicked = () => {
        setShowNavigationItem(showCryptoTable);
    }

    const onCryptoRowClicked = (id: string, symbol: string) => {
        actions.executeGetTweets(symbol).catch((error: any) => {
            actions.executeTweetsClearSuccess();
            console.log("Error Getting Tweets: " + error);
        });
        actions.executeGetCryptoNews(symbol).catch((error: any) => {
            actions.executeNewsClearSuccess();
            console.log("Error Getting News: " + error);
        });
        actions.executeGetCryptoAnalysis(symbol).catch((error: any) => {
            console.log("Error Getting Analysis: " + error);
        });
        Promise.all([
            actions.executeGetCryptoHistoricalPriceBySymbol(symbol),
            actions.executeGetCryptoById(id),            
        ]).then(() => setShowNavigationItem(showCryptoDetailCard))
        .catch((error: any) => {
            console.log("Error Executing Get Crypto By Id: " + error)
        });
    }

    const onFavoritesToggle = (eventDto: CryptoDto) => {
        actions.executeToggleFavorite(eventDto, eventFavorites)
        .then(() => actions.executeLoadFavorites())            
            .catch((error: any) => {
                console.log("Error: " + error)
            });
    }

    const onEventDetailCardBackClicked = () => {
        setShowNavigationItem(showCryptoTable);
    }

    const showCryptoTable = "showCryptoTable";
    const showCryptoDetailCard = "showCryptoDetailCard";

    const [showNavigationItem, setShowNavigationItem] = useState(showCryptoTable);

    useEffect(() => {
        //Reset eventResults, eventDetails and userCoordinates from Redux state.
        actions.executeEventSearchClear();
        actions.executeGetEventByIdClear();
        actions.executeGetCoordinatesClear();
    }, [""]);

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    { showNavigationItem == showCryptoTable && <CryptoSearchForm submitted={onSearchEventsClicked} />}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {
                        {
                            showCryptoTable: <CryptoSearchResultsTable
                                searchResults={cryptoSearchResults}
                                onRowClicked={onCryptoRowClicked} />,
                            showCryptoDetailCard: <CryptoDetailsCard
                                cryptoDetails={cryptoDetails}
                                cryptoNews={cryptoNews}
                                cryptoAnalysis={cryptoAnalysis}
                                loginUserResult={loginUserResult}
                                cryptoHistoricalPrices={cryptoHistoricalPrices}
                                eventFavorites={eventFavorites}
                                cryptoTweets={cryptoTweets}
                                favoritesToggle={onFavoritesToggle}
                                onBackClicked={onEventDetailCardBackClicked} />,
                            '': ''
                        }[showNavigationItem]
                    }

                </div>
            </div>
        </>
    );
}

function mapStateToProps(state: any) {
    return {
        cryptoHistoricalPrices: state.eventReducer.cryptoHistoricalPrices,
        cryptoSearchResults: state.eventReducer.cryptoSearchResults,
        cryptoDetails: state.eventReducer.cryptoDetails,
        cryptoAnalysis: state.eventReducer.cryptoAnalysis,
        cryptoNews: state.eventReducer.cryptoNews,
        eventFavorites: state.eventReducer.eventFavorites,
        cryptoTweets: state.eventReducer.cryptoTweets,
        loginUserResult: state.eventReducer.loginUserResult
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(eventSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CryptoLandingPage);