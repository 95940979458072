import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from "../../config"
import { bindActionCreators } from 'redux';
import * as cryptoSearchActions from '../../redux/actions/cryptoSearchActions';
import { useNavigate, useLocation } from 'react-router-dom';
import RequiredFieldTooltip from '../tooltips/RequiredFieldTooltip';
import { LoginPageProps } from '../../types/props/LoginPageProps';

function LoginPage({ loginUserResult, actions }: LoginPageProps) {
    const nav = useNavigate();
    const [showPasswordRequiredTooltip, setShowPasswordRequiredTooltip] = useState(false);
    const [showEmailRequiredTooltip, setShowEmailRequiredTooltip] = useState(false);
    const [showInvalidLoginInfoUI, setShowInvalidLoginInfoUI] = useState(false);
    const [showLoadingLogin, setShowLoadingLogin] = useState(false);

    useEffect(() => {
        setShowLoadingLogin(false);
        if (loginUserResult && loginUserResult.errorMessage && loginUserResult.errorMessage.length > 0) {
            setShowInvalidLoginInfoUI(true);
        } else if(loginUserResult && loginUserResult.userId && loginUserResult.userId.length > 0){            
            setShowInvalidLoginInfoUI(false);
            nav("/search");
        }
        return () => {
            console.log('Login page unmounted');
            // Moving out of the login page with login errors. Then, clear the user login data from the state.
            if (loginUserResult && loginUserResult.errorMessage && loginUserResult.errorMessage.length > 0) {
                  actions.executeLogoutUser();
              }           
          };
    }, [loginUserResult])

    const [loginFormParams, setLoginFormParams] = useState({
        email: "",
        password: ""
    });

    const onEmailChanged = (e: any) => {
        setLoginFormParams({ ...loginFormParams, email: e.target.value });
    }

    const onPasswordChanged = (e: any) => {
        setLoginFormParams({ ...loginFormParams, password: e.target.value });
    }

    const onLoginClicked = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        
        if (!loginFormParams.email) {
            setShowEmailRequiredTooltip(true);
            return;
        } else if (!loginFormParams.password) {
            setShowPasswordRequiredTooltip(true);
            return;
        } else if (loginFormParams.password.length < config.minPasswordLength) {
            alert("Password must be at least " + config.minPasswordLength + " characters long!")
            return;
        } 

        setShowLoadingLogin(true);

        actions.executeLoginUser(loginFormParams)
               .then(() => actions.getFavorites())
               .catch((error: any) => {
                   console.log("Error Loggin User: " + error)
               });
    }

    return (
        <div className="row d-flex justify-content-center mt-5">
        <div className="col-lg-6">
            <div className="card text-white frosted-background rounded-corners">
                <form onSubmit={onLoginClicked}>
                    <div className="card-body">
                        <div className="search-form-title pt-5">Login</div>
                        <div className="search-form-title-hr" />
                        <div className="form-group">
                            <label className="form-label mt-4">Email</label>&nbsp;<span className="text-danger"><strong>*</strong></span>
                            <input type="text" className="form-control"
                                value={loginFormParams.email} 
                                onChange={onEmailChanged} />
                            <RequiredFieldTooltip key="email-required-tooltip" visible={showEmailRequiredTooltip} />                           
                        </div>
                        <div className="form-group">
                            <label className="form-label mt-4">Password</label>&nbsp;<span className="text-danger"><strong>*</strong></span>
                            <input type="password" className="form-control"
                                value={loginFormParams.password} 
                                onChange={onPasswordChanged} />
                            <RequiredFieldTooltip key="password-required-tooltip" visible={showPasswordRequiredTooltip} />                           
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4 text-center">
                                {showInvalidLoginInfoUI ? 
                                <div className="alert alert-danger fade show" role="alert">
                                    Please check login credentials or <a href="#" onClick={(e) => { e.preventDefault(); nav(`/register`); }} className="alert-link">register.</a>
                                </div> : <></>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4 pb-5 text-center">
                                <button type="submit" className={"btn btn-primary me-3 " + (showLoadingLogin ? 'disabled' : '')}>
                                    {showLoadingLogin ? <span className="spinner-border spinner-border-sm text-light me-1">
                                    </span> : <></>}
                                    <span>LOGIN</span>
                                </button>
                                <button type="button" onClick={() => { nav(`/register`); }}
                                    className="btn btn-success">REGISTER</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    );
}

function mapStateToProps(state: any) {
    return {
        loginUserResult: state.eventReducer.loginUserResult
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(cryptoSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);