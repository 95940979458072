import { handleResponse, handleError } from "./apiUtils";
import { CryptoSearchParams } from "../types/userInput/CryptoSearchParams";
import config from "../config";
import { CryptoDto } from "../types/reactOutput/CryptoDto";
import { RegisterUserParams } from "../types/userInput/RegisterUserParams";
import { LoginUserParams } from "../types/userInput/LoginUserParams";

//http://localhost:5001/cryptocurrencies/search?keyword=et
export function getKeywordSuggestions(keyword: string) {
    let getKeywordSuggestionsUrl = `${config.baseApiUrl}cryptocurrencies/search?keyword=${keyword}`
    return fetch(getKeywordSuggestionsUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getCryptoByParams(cryptoSearchQueryParams: CryptoSearchParams) {

    let apiUrl = `${config.baseApiUrl}cryptocurrencies/search?keyword=${cryptoSearchQueryParams.keyword}`;

    return fetch(apiUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getCrypto(){
    let getCryptoUrl = `${config.baseApiUrl}cryptocurrencies/coinmarketcapproxy?url=https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?start=1&limit=100&sort=name&cryptocurrency_type=all&tag=all`;

    return fetch(getCryptoUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getTweets(symbol: string){
    let getCryptoUrl = `${config.baseApiUrl}cryptocurrencies/${symbol}/tweets`;

    return fetch(getCryptoUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function loginUser(loginUserParams: LoginUserParams){
    let loginUserUrl = `${config.baseApiUrl}user/login`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: loginUserParams.email, password: loginUserParams.password })
    }

    return fetch(loginUserUrl, requestOptions)
    .then(handleResponse)
    .catch(handleError)
}

export function registerUser(registerUserParams: RegisterUserParams){
    let registerUserUrl = `${config.baseApiUrl}user/register`;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: registerUserParams.email, password: registerUserParams.password })
      };

    return fetch(registerUserUrl, requestOptions)
        .then(handleResponse)
        .catch(handleError)
}

export function getCryptoById(cryptoId: number) {
    let apiUrl = `${config.baseApiUrl}cryptocurrencies/coinmarketcapproxy?url=https://pro-api.coinmarketcap.com/v2/cryptocurrency/info?id=${cryptoId}`
    return fetch(apiUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getCryptoAnalysis(symbol: string){
    let apiUrl = `${config.baseApiUrl}cryptocurrencies/${symbol}/analysis`
    return fetch(apiUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getCryptoNews(symbol: string){
    let apiUrl = `${config.baseApiUrl}cryptocurrencies/${symbol}/news`
    return fetch(apiUrl)
        .then(handleResponse)
        .catch(handleError)
}

export function getCryptoHistoricalPricesBySymbol(symbol: string) {
    let apiUrl = `${config.baseApiUrl}cryptocurrencies/${symbol}/history?limit=100`
    return fetch(apiUrl)
        .then(handleResponse)
        .catch(handleError)
}

/*
If eventFavorites contains favoriteEvent then remove favoriteEvent from eventFavorites.
Else eventFavorites does not contain favoriteEvent then add favoriteEvent to eventFavorites. 
*/
export function toggleFavorites(eventDto: CryptoDto, eventFavorites: CryptoDto[]) {
    let toggleFavoritesUrl = `${config.baseApiUrl}user/togglefavorites`;
    let user: string | null = localStorage.getItem('user');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            id: user == null ? "" : JSON.parse(user).userId,
            email: user  == null ? "" : JSON.parse(user).email, 
            crypto_symbol: eventDto.symbol })
    }

    return fetch(toggleFavoritesUrl, requestOptions)
    .then(handleResponse)
    .catch(handleError)

    // Old Code 
    // const toggleFavoritePromise = new Promise<CryptoDto[]>((resolve, reject) => {
    //     var eventDtoIsInFavorites = eventFavorites && eventFavorites.find(event => event.eventId == eventDto.eventId);
    //     // If eventFavorites contains favoriteEvent then remove favoriteEvent from eventFavorites. 
    //     if (eventDtoIsInFavorites) {
    //         favoritesLocalStorage.deleteFavoriteEvent(eventDto.eventId)
    //     }
    //     // Else eventFavorites does not contain favoriteEvent then add favoriteEvent to eventFavorites. 
    //     else {
    //         favoritesLocalStorage.addFavoriteEvent(eventDto);
    //     }

    //     let newEventFavorites = favoritesLocalStorage.getFavoriteEvents();
    //     resolve(newEventFavorites);
    // });
    // return toggleFavoritePromise;
}

export function getFavorites() {
    let getFavoritesUrl = `${config.baseApiUrl}user/getfavorites`;
    let user: string | null = localStorage.getItem('user');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            id: user == null ? "" : JSON.parse(user).userId,
            email: user  == null ? "" : JSON.parse(user).email})
    }

    return fetch(getFavoritesUrl, requestOptions)
    .then(handleResponse)
    .catch(handleError)

    // OLD Code
    // const getFavoritesPromise = new Promise<CryptoDto[]>((resolve, reject) => {
    //     let eventFavorites = favoritesLocalStorage.getFavoriteEvents(user_id);
    //     resolve(eventFavorites);
    // });
    // return getFavoritesPromise;
}

export function checkFavorites(eventDto: CryptoDto, eventFavorites: CryptoDto[]) {
    let checkFavoritesUrl = `${config.baseApiUrl}user/checkfavorites`;
    let user: string | null = localStorage.getItem('user');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            id: user == null ? "" : JSON.parse(user).userId,
            email: user  == null ? "" : JSON.parse(user).email, 
            crypto_symbol: eventDto.symbol })
    }
    
    return fetch(checkFavoritesUrl, requestOptions)
    .then(handleResponse)
    .catch(handleError)
}