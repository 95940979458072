import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
// Documentation https://mui.com/material-ui/react-autocomplete/
import Autocomplete from '@mui/material/Autocomplete';
import * as eventSearchActions from '../../redux/actions/cryptoSearchActions'; 
import useDebounce from '../../hooks/useDebounce';
import config from '../../config';
import RequiredFieldTooltip from '../tooltips/RequiredFieldTooltip';
import { CryptoSearchAutocompleteDto } from "../../types/reactOutput/CryptoSearchAutocompleteDto";

type EventSearchFormProps = {
    eventSearchKeywordSuggestions: CryptoSearchAutocompleteDto[],
    actions: any,
    submitted: () => void;
}

const CryptoSearchForm = ({ eventSearchKeywordSuggestions, actions, submitted }: EventSearchFormProps) => {

    const [showKeywordRequiredTooltip, setShowKeywordRequiredTooltip] = useState(false);
    const [cryptoSearchFormParams, setCryptoSearchQueryParams] = useState({
        keyword: ""
    });
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly string[]>([]);
    const debouncedKeyword = useDebounce<string>(cryptoSearchFormParams.keyword, config.halfSecond);

    const onSearchEventsClicked = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!cryptoSearchFormParams.keyword) {
            setShowKeywordRequiredTooltip(true);
            return;
        }
        setShowKeywordRequiredTooltip(false);
        actions.executeSearchCryptoByParams(cryptoSearchFormParams)
                .then(() => submitted())
                .catch((error: any) => {
                    console.log("Error Executing Crypto Search: " + error)
                });
    }

    const onKeywordInputChanged = (e: any, newKeyword: string) => {
        setCryptoSearchQueryParams({ ...cryptoSearchFormParams, keyword: newKeyword });

        if(newKeyword)
            setShowKeywordRequiredTooltip(false);
    }

    //Start with a clear state.
    useEffect(() => {
        //Reset eventResults, eventDetails and userCoordinates from Redux state.
        actions.executeEventSearchClear();
        actions.executeGetEventByIdClear();
        actions.executeGetCoordinatesClear();
    }, [""]);

    useEffect(() => {
        if (eventSearchKeywordSuggestions) {
            let autocompleteItems = eventSearchKeywordSuggestions.map((crypto) => crypto.name);
            setOptions([...autocompleteItems])
        }
    }, [eventSearchKeywordSuggestions]);

    useEffect(() => {
        if (cryptoSearchFormParams.keyword) {
            actions.executeKeywordSuggestions(cryptoSearchFormParams.keyword)
                .catch((error: any) => {
                    console.log(`Error executeKeywordSuggestions(${cryptoSearchFormParams.keyword}): error ${error}`)
                });
        }

        return () => {
            setOptions([]);
        };
    }, [debouncedKeyword]);

    return (
        <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-10">
                <div className="card text-white frosted-background rounded-corners">
                    <div className="card-body">
                        <div className="search-form-title">Crypto Buddy</div>
                        <div className="form-group mt-4">
                            <Autocomplete
                                id="keyword-asynchronous-search"
                                freeSolo
                                disableClearable
                                popupIcon={""}
                                sx={{}}
                                size="small"
                                open={open}
                                onOpen={() => {
                                    if (cryptoSearchFormParams.keyword) {
                                        setOpen(true);
                                    }
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                filterOptions={(x) => x}
                                getOptionLabel={(option) => option}
                                options={options}
                                loading={cryptoSearchFormParams.keyword != ""}
                                loadingText={<CircularProgress color="inherit" size={20} />}
                                inputValue={cryptoSearchFormParams.keyword}
                                onInputChange={onKeywordInputChanged}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="form-control"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        placeholder="keyword"
                                    />
                                )}
                            />
                            <RequiredFieldTooltip key="keyword-required-tooltip" visible={showKeywordRequiredTooltip} />
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-4 text-center">
                                <button type="button" onClick={onSearchEventsClicked} className="btn btn-primary btn-lg">SEARCH</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        eventSearchKeywordSuggestions: state.eventReducer.eventSearchKeywordSuggestions
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(eventSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CryptoSearchForm);