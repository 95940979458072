const config = {
    minPasswordLength: 8,
    oneSecond: 1000,
    halfSecond: 500,
    ipInfoUrl: 'https://ipinfo.io/?token=6b82be82b8ada2',
    baseApiUrl: "http://localhost:5001/",
    spotifyAuthenticationUrl: 'https://accounts.spotify.com/api/token',
    spotifyClientId: "8caee251441c411e994439bb5afc18ed",
    spotifyClientSecret: "40db3bbc7d6440eeb95648beb6a9a3b0",
    googleMapsUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCCrO2aS8Imi-baaxR0M9t8tvhnRYpXQ2Y',
    googleApiKey: 'AIzaSyCCrO2aS8Imi-baaxR0M9t8tvhnRYpXQ2Y'
}

export default config;