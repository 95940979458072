export const EXECUTE_KEYWORD_SUGGESTIONS_SUCCESS = "EXECUTE_KEYWORD_SUGGESTIONS_SUCCESS";
export const EXECUTE_GET_COORDINATES_CLEAR_SUCCESS = "EXECUTE_GET_COORDINATES_CLEAR_SUCCESS";
export const EXECUTE_GET_COORDINATES_FROM_IP_SUCCESS = "EXECUTE_GET_COORDINATES_FROM_IP_SUCCESS";
export const EXECUTE_GET_COORDINATES_FROM_LOCATION_TEXT_SUCCESS = "EXECUTE_GET_COORDINATES_FROM_LOCATION_TEXT_SUCCESS";
export const EXECUTE_REGISTER_USER_SUCCESS = "EXECUTE_REGISTER_USER_SUCCESS";
export const EXECUTE_CLEAR_REGISTRATION_INFO = "EXECUTE_CLEAR_REGISTRATION_INFO";
export const EXECUTE_LOGIN_USER_SUCCESS = "EXECUTE_LOGIN_USER_SUCCESS";
export const EXECUTE_LOGOUT_USER_SUCCESS = "EXECUTE_LOGOUT_USER_SUCCESS";
export const EXECUTE_EVENT_SEARCH_CLEAR_SUCCESS = "EXECUTE_EVENT_SEARCH_CLEAR_SUCCESS";
export const EXECUTE_GET_EVENT_BY_ID_CLEAR_SUCCESS = "EXECUTE_GET_EVENT_BY_ID_CLEAR_SUCCESS";
export const EXECUTE_GET_CRYPTO_BY_ID_SUCCESS = "EXECUTE_GET_CRYPTO_BY_ID_SUCCESS";
export const EXECUTE_TOGGLE_FAVORITE_SUCCESS = "EXECUTE_TOGGLE_FAVORITE_SUCCESS";
export const EXECUTE_LOAD_FAVORITES_SUCCESS = "EXECUTE_LOAD_FAVORITES_SUCCESS";
export const EXECUTE_CHECK_FAVORITES_SUCCESS = "EXECUTE_CHECK_FAVORITES_SUCCESS";
export const EXECUTE_LOAD_ARTIST_DATA_FROM_SPOTIFY_SUCCESS = "EXECUTE_LOAD_ARTIST_DATA_FROM_SPOTIFY_SUCCESS";
export const EXECUTE_LOAD_CRYPTO_SUCCESS = "EXECUTE_LOAD_CRYPTO_SUCCESS";
export const EXECUTE_LOAD_CRYPTO_HISTORICAL_PRICES_SUCCESS = "EXECUTE_LOAD_CRYPTO_HISTORICAL_PRICES_SUCCESS";
export const EXECUTE_SEARCH_CRYPTO_BY_PARAMS_SUCCESS = "EXECUTE_SEARCH_CRYPTO_BY_PARAMS_SUCCESS";
export const EXECUTE_GET_CRYPTO_ANALYSIS_SUCCESS = "EXECUTE_GET_CRYPTO_ANALYSIS_SUCCESS";
export const EXECUTE_GET_CRYPTO_NEWS_SUCCESS = "EXECUTE_GET_CRYPTO_NEWS_SUCCESS";
export const EXECUTE_GET_TWEETS_SUCCESS = "EXECUTE_GET_TWEETS_SUCCESS";