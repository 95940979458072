import React, { useState } from 'react';
import { CryptoDetailsCardProps } from '../../../types/props/CryptoDetailsCardProps';
//https://www.npmjs.com/package/react-circular-progressbar
//https://codesandbox.io/s/vymm4oln6y?file=/index.js
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import ReactTimeAgo from 'react-time-ago'


const CryptoTweets = ({ cryptoTweets }: CryptoDetailsCardProps) => {

    return (
        <>
        <div className='row mt-4 scrollable-crypto-section'>
            <div className='col-lg-12'>
                {cryptoTweets && cryptoTweets.length > 0 ?
                    <>{cryptoTweets.map(tweet => (
                        <div key={tweet.author + tweet.timestamp.toLocaleString()} className="card bg-dark text-white mb-4">
                            <div className="card-header">
                                <div className="d-flex">
                                    <div className="flex-grow-1 align-self-center">
                                        <a href={`https://x.com/${tweet.author}`} className="btn btn-outline-success">{tweet.author}</a>
                                    </div>
                                    <div className="align-self-center me-2">
                                        <span>Sentiment</span>
                                    </div>
                                    <div className="" style={{ width: 50, height: 50 }}>
                                        <CircularProgressbar
                                            styles={buildStyles({
                                                backgroundColor: "#F8F9FA",
                                                textColor: "#ffffff",
                                                textSize: "25px",
                                                pathColor: "#16DB65",
                                                trailColor: "#6C757D",
                                                strokeLinecap: "butt",                                                    
                                                pathTransitionDuration: 0.5,
                                            })} 
                                            minValue={-1}
                                            maxValue={1}
                                            strokeWidth={15} 
                                            value={tweet.sentiment_score}
                                            text={`${tweet.sentiment_score.toFixed(3)}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="card-text">{tweet.tweet_text}</p>
                            </div>
                            <div className="card-footer text-success">
                                <div className="d-flex bd-highlight">
                                    <div className="flex-grow-1 bd-highlight">
                                        <ReactTimeAgo date={new Date(tweet.timestamp + 'Z')} locale="en-US"/>  
                                    </div>
                                    <div className="bd-highlight">
                                        <span>{new Date(tweet.timestamp).toLocaleDateString('en-US', {
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                        </span>  
                                    </div>
                                </div>                             
                            </div>
                        </div> 
                    ))}</>
                    : 
                    <div className="alert alert-dark alert-dismissible text-center fade show" role="alert">
                        Tweets unavailable at the moment. Try again later.
                    </div>
                }
            </div>
        </div>         
        </>
    );
}

export default CryptoTweets;