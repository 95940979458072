import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as cryptoSearchActions from '../../redux/actions/cryptoSearchActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoginUserResult } from '../../types/reactOutput/LoginUserResult';

const MainMenuToggle = ({
    loginUserResult,
    actions }: { loginUserResult: LoginUserResult, actions: any }) => {

    const nav = useNavigate();
    const location = useLocation();
    const searchMainPage = "searchMainPage";
    const favoritesMainPage = "favoritesMainPage";
    const loginMainPage = "loginMainPage";
    const registerMainPage = "registerMainPage";
    const [showNavigationItem, setShowNavigationItem] = useState(searchMainPage);

    useEffect(() => {
        if (location.pathname.endsWith("/favorites")) {
            setShowNavigationItem(favoritesMainPage);
        } else if(location.pathname.endsWith("/login")){
            setShowNavigationItem(loginMainPage);
        } else if(location.pathname.endsWith("/register")){
            setShowNavigationItem(registerMainPage);
        }else if(location.pathname.endsWith("/search")){
            setShowNavigationItem(searchMainPage);
        }
    }, [location])

    const onLogoutClicked = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        actions.executeLogoutUser();
        nav(`/search`);
    }

    return (
        <div className="row mt-3 pb-4">
            <div className="col-lg-12 text-end">
                <span onClick={() => { nav(`/search`); setShowNavigationItem(searchMainPage) }}
                    className={"me-2 py-2 px-3 btn-nav " + (showNavigationItem == searchMainPage ? 'btn-nav-active' : '')}>
                    Search
                </span>

                {loginUserResult && loginUserResult.email && loginUserResult.email.length > 0 ? 
                    <>
                        <span onClick={() => { nav(`/favorites`); setShowNavigationItem(searchMainPage) }}
                            className={"me-2 py-2 px-3 btn-nav " + (showNavigationItem == favoritesMainPage ? 'btn-nav-active' : '')}>
                            Favorites
                        </span>
                        <span className={"me-2 py-2 px-3 text-white"}>
                            {loginUserResult.email}
                        </span>
                        <span onClick={onLogoutClicked}
                            className={"me-2 py-2 px-3 btn-nav " + (showNavigationItem == registerMainPage ? 'btn-nav-active' : '')}>
                            Logout
                        </span>
                    </>
                    :
                    <>
                        <span onClick={() => { nav(`/login`); setShowNavigationItem(searchMainPage) }}
                            className={"me-2 py-2 px-3 btn-nav " + (showNavigationItem == loginMainPage ? 'btn-nav-active' : '')}>
                            Login
                        </span>
                        <span onClick={() => { nav(`/register`); setShowNavigationItem(searchMainPage) }}
                            className={"me-2 py-2 px-3 btn-nav " + (showNavigationItem == registerMainPage ? 'btn-nav-active' : '')}>
                            Register
                        </span>
                    </>
                }
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        loginUserResult: state.eventReducer.loginUserResult
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(cryptoSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuToggle);