import React, { useState } from 'react';
import { CryptoDetailsCardNavigationProps } from '../../types/props/CryptoDetailsCardNavigationProps';
import CryptoInfo from './cryptoDetailsNav/CryptoInfo';
import CryptoHistoricalPricingTable from './cryptoDetailsNav/CryptoHistoricalPricingTable';
import CryptoNews from './cryptoDetailsNav/CryptoNews';
import CryptoTweets from './cryptoDetailsNav/CryptoTweets';


const CryptoDetailsCardNavigation = ({
    cryptoDetails,
    cryptoAnalysis,
    cryptoNews,
    cryptoTweets,
    cryptoHistoricalPrices }: CryptoDetailsCardNavigationProps) => {

    const cryptoInfoTab = "cryptoInfoTab";
    const historicalPricesTab = "historicalPricesTab";
    const cryptoNewsTab = "cryptoNewsTab";
    const cryptoTweetsTab = "cryptoTweetsTab";

    const [showNavigationItem, setShowNavigationItem] = useState(cryptoInfoTab);

    return (
        <>
            <div className="row bg-success">
                <div className="col-lg-12 d-flex justify-content-center flex-nowrap">
                    <nav className="navbar no-padding navbar-expand navbar-dark ">
                        <div className="container-fluid">
                            <ul className="navbar-nav">
                                <li className="nav-item cursor-pointer" onClick={() => setShowNavigationItem(cryptoInfoTab)}>
                                    <a className={"nav-link py-3 px-5 " + (showNavigationItem == cryptoInfoTab ? 'active' : '')}>Info</a>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setShowNavigationItem(historicalPricesTab)}>
                                    <a className={"nav-link py-3 px-5 " + (showNavigationItem == historicalPricesTab ? 'active' : '')}>Prices</a>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setShowNavigationItem(cryptoNewsTab)}>
                                    <a className={"nav-link py-3 px-5 " + (showNavigationItem == cryptoNewsTab ? 'active' : '')}>News</a>
                                </li>
                                <li className="nav-item cursor-pointer" onClick={() => setShowNavigationItem(cryptoTweetsTab)}>
                                    <a className={"nav-link py-3 px-5 " + (showNavigationItem == cryptoTweetsTab ? 'active' : '')}>Tweets</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    {
                        {
                            cryptoInfoTab: <CryptoInfo 
                                cryptoHistoricalPrices={cryptoHistoricalPrices}
                                cryptoNews={cryptoNews}
                                cryptoAnalysis={cryptoAnalysis}
                                cryptoTweets={cryptoTweets} 
                                cryptoDetails={cryptoDetails} />,                            
                            historicalPricesTab: <CryptoHistoricalPricingTable 
                                cryptoHistoricalPrices={cryptoHistoricalPrices} 
                                cryptoNews={cryptoNews}
                                cryptoTweets={cryptoTweets}
                                cryptoAnalysis={cryptoAnalysis} 
                                cryptoDetails={cryptoDetails} />,
                            cryptoNewsTab: <CryptoNews 
                                cryptoHistoricalPrices={cryptoHistoricalPrices} 
                                cryptoNews={cryptoNews}
                                cryptoTweets={cryptoTweets}
                                cryptoAnalysis={cryptoAnalysis} 
                                cryptoDetails={cryptoDetails} />,
                            cryptoTweetsTab: <CryptoTweets 
                                cryptoTweets={cryptoTweets}
                                cryptoHistoricalPrices={cryptoHistoricalPrices} 
                                cryptoNews={cryptoNews}
                                cryptoAnalysis={cryptoAnalysis} 
                                cryptoDetails={cryptoDetails} />,
                            '': ''
                        }[showNavigationItem]
                    }
                </div>
            </div>
        </>

    );
}

export default CryptoDetailsCardNavigation;