import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as eventSearchActions from '../../redux/actions/cryptoSearchActions';
import { FavoritesPageProps } from '../../types/props/FavoritesPageProps';
import { CryptoDto } from '../../types/reactOutput/CryptoDto';
import { useNavigate } from 'react-router-dom';

function FavoritesPage({ eventFavorites, loginUserResult, actions }: FavoritesPageProps) {
    const nav = useNavigate();
    const onDeleteFromFavorites = (eventDto: CryptoDto) => {
        actions.executeToggleFavorite(eventDto, eventFavorites)
            .then(() => alert("Removed from favorites!"))
            .catch((error: any) => {
                console.log("Error Executing Get Event By Id: " + error)
            });
    }

    useEffect(() => {
        if (!loginUserResult || !loginUserResult.email || !loginUserResult.userId) {
            nav('/search');
        }
    }, [loginUserResult])

    useEffect(() => {
        if (!eventFavorites) {
            actions.executeLoadFavorites()
                .catch((error: any) => {
                    console.log("Error Executing Get Event By Id: " + error)
                });
        }
        console.log(eventFavorites);
    }, [eventFavorites])


    return (
        <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-8">
                {eventFavorites && eventFavorites.length > 0 ?
                    <>
                        <span className="span-event-details-label text-center pb-3">List of your favorite cryptos</span>
                        <table className="table table-hover table-light table-rounded-corners">
                            <thead>
                                <tr className="text-center">
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Symbol</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Market Cap</th>
                                    <th scope="col">Volume (24h)</th>
                                    <th scope="col">Circulating Supply</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventFavorites.map((favorite, index) => (
                                    <tr key={favorite.id} className="text-center">
                                        <th scope="row">{index + 1}</th>
                                        <td>{favorite.name}</td>
                                        <td>{favorite.symbol}</td>
                                        <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(favorite.price)}</td>
                                        <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(favorite.market_cap)}</td>
                                        <td>{favorite.volume_24h.toLocaleString()}</td>
                                        <td>{favorite.circulating_supply.toLocaleString()}</td>
                                        <td>
                                            <span className="material-icons" onClick={() => onDeleteFromFavorites(favorite)}>
                                                delete_outline
                                            </span>
                                        </td>
                                    </tr>))}
                            </tbody>
                        </table>
                    </>
                    :
                    <div className="badge rounded-pill bg-light w-100 text-wrap">
                        <h5 className="text-danger mb-0">No favorite cryptos to show</h5>
                    </div>
                }
            </div>
        </div>
    );
}

function mapStateToProps(state: any) {
    return {
        eventFavorites: state.eventReducer.eventFavorites,
        loginUserResult: state.eventReducer.loginUserResult
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(eventSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesPage);