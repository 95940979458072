import React, { useEffect, useState } from 'react';
import config from "../../config"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as eventSearchActions from '../../redux/actions/cryptoSearchActions';
import { RegisterPageProps } from '../../types/props/RegisterPageProps';
import RequiredFieldTooltip from '../tooltips/RequiredFieldTooltip';
import { useNavigate } from 'react-router-dom';

const RegisterPage = ({ registerUserResult, actions }: RegisterPageProps) => {
    const nav = useNavigate();
    const [showPasswordRequiredTooltip, setShowPasswordRequiredTooltip] = useState(false);
    const [showConfirmPasswordRequiredTooltip, setShowConfirmPasswordRequiredTooltip] = useState(false);
    const [showEmailRequiredTooltip, setShowEmailRequiredTooltip] = useState(false);
    const [showLoadingRegistration, setShowLoadingRegistration] = useState(false);
    const [showInvalidRegistrationInfoUI, setShowInvalidRegistrationInfoUI] = useState(false);

    const [registerFormParams, setRegisterParams] = useState({
        email: "",
        password: "",
        confirm_password: "",
    });

    useEffect(() => {
        setShowLoadingRegistration(false);
        if(registerUserResult && registerUserResult.errorMessage && registerUserResult.errorMessage.length > 0){
            setShowInvalidRegistrationInfoUI(true)
        }else if(registerUserResult && registerUserResult.userId && registerUserResult.userId.length > 0){            
            
            nav("/search");
        }
        return () => {
            console.log('Register page unmounted');
            // Moving out of the login page with login errors. Then, clear the user login data from the state.
            if (registerUserResult && registerUserResult.errorMessage && registerUserResult.errorMessage.length > 0) {
                  actions.executeClearRegistrationInfo();
              }           
          };
    }, [registerUserResult])

    const onEmailChanged = (e: any) => {
        setRegisterParams({ ...registerFormParams, email: e.target.value });
    }

    const onPasswordChanged = (e: any) => {
        setRegisterParams({ ...registerFormParams, password: e.target.value });
    }

    const onConfirmPasswordChanged = (e: any) => {
        setRegisterParams({ ...registerFormParams, confirm_password: e.target.value });
    }

    const onRegisterClicked = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!registerFormParams.email) {
            setShowEmailRequiredTooltip(true);
            return;
        } else if (!registerFormParams.password) {
            setShowPasswordRequiredTooltip(true);
            return;
        } else if (registerFormParams.password.length < config.minPasswordLength) {
            alert("Password must be at least " + config.minPasswordLength + " characters long!")
            return;
        } else if (!registerFormParams.confirm_password) {
            setShowConfirmPasswordRequiredTooltip(true);
            return;
        } else if (registerFormParams.password != registerFormParams.confirm_password){
            alert("Passwords do NOT match!")
            return;
        }
        setShowLoadingRegistration(true);
        actions.executeRegisterUser(registerFormParams)
               .then(() => {
                    actions.executeLoginUser({
                        email: registerFormParams.email,
                        password: registerFormParams.password
                    }).catch((error: any) => {
                        console.log("Error Login User: " + error)
                    });
               })
               .catch((error: any) => {
                   console.log("Error Registering User: " + error)
               });
    }

    return (
        <div className="row d-flex justify-content-center mt-5">
        <div className="col-lg-6">
            <div className="card text-white frosted-background rounded-corners">
                <div className="card-body">
                    <div className="search-form-title pt-5">Register</div>
                    <div className="search-form-title-hr" />
                    <div className="form-group">
                        <label className="form-label mt-4">Email</label>&nbsp;<span className="text-danger"><strong>*</strong></span>
                        <input type="text" className="form-control"
                            value={registerFormParams.email} 
                            onChange={onEmailChanged} />
                        <RequiredFieldTooltip key="email-required-tooltip" visible={showEmailRequiredTooltip} />                           
                    </div>
                    <div className="form-group">
                        <label className="form-label mt-4">Password</label>&nbsp;<span className="text-danger"><strong>*</strong></span>
                        <input type="password" className="form-control"
                            value={registerFormParams.password} 
                            onChange={onPasswordChanged} />
                        <RequiredFieldTooltip key="password-required-tooltip" visible={showPasswordRequiredTooltip} />                           
                    </div>
                    <div className="form-group">
                        <label className="form-label mt-4">Confirm Password</label>&nbsp;<span className="text-danger"><strong>*</strong></span>
                        <input type="password" className="form-control"
                            value={registerFormParams.confirm_password} 
                            onChange={onConfirmPasswordChanged} />
                        <RequiredFieldTooltip key="confirm-password-required-tooltip" visible={showConfirmPasswordRequiredTooltip} />                           
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt-4 text-center">
                            {showInvalidRegistrationInfoUI ? 
                            <div className="alert alert-danger fade show" role="alert">
                                {registerUserResult.errorMessage}
                            </div> : <></>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt-4 pb-5 text-center">
                            <button type="button" onClick={onRegisterClicked} className={"btn btn-primary " + (showLoadingRegistration ? 'disabled' : '')}>
                                {showLoadingRegistration ? <span className="spinner-border spinner-border-sm text-light me-1">
                                </span> : <></>}
                                <span>REGISTER</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

function mapStateToProps(state: any) {
    return {
        registerUserResult: state.eventReducer.registerUserResult
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(eventSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);