import React from 'react';
import { CryptoDto } from '../../types/reactOutput/CryptoDto';

type EventSearchResultsTableProps = {
    searchResults: CryptoDto[];
    onRowClicked: (id: string, symbol: string) => void;
}

const CryptoSearchResultsTable = ({ searchResults, onRowClicked }: EventSearchResultsTableProps) => {

    const onCryptoRowClicked = (id: string, symbol: string) => {
        onRowClicked(id, symbol);
    }

    return (
        <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-10">
                {searchResults && searchResults.length > 0 ?
                    <table className="table table-dark table-striped table-hover table-rounded-corners">
                        <thead>
                            <tr className="text-center">
                                <th scope="col">Name</th>
                                <th scope="col">Symbol</th>
                                <th scope="col">Price</th>
                                <th scope="col">Market Cap</th>
                                <th scope="col">Volume (24h)</th>
                                <th scope="col">Circulating Supply</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map(cryptoResult => (
                                <tr onClick={() => onCryptoRowClicked(cryptoResult.id, cryptoResult.symbol)} key={cryptoResult.id} className="text-center cursor-pointer">
                                    <td>{cryptoResult.name}</td>
                                    <td>{cryptoResult.symbol}</td>
                                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(cryptoResult.price)}</td>
                                    <td>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(cryptoResult.market_cap)}</td>
                                    <td>{cryptoResult.volume_24h.toLocaleString()}</td>
                                    <td>{cryptoResult.circulating_supply.toLocaleString()}</td>
                                </tr>))
                            }
                        </tbody>
                    </table>
                    :
                    searchResults && searchResults.length == 0 ?
                        <div className="badge rounded-pill bg-light w-100 text-wrap">
                            <h5 className="text-danger mb-0">No results available</h5>
                        </div> : <></>
                }
            </div>
        </div>
    );
}

export default CryptoSearchResultsTable;