import React from 'react';
import { RequiredFieldTooltipProps } from '../../types/props/RequiredFieldTooltipProps';

const RequiredFieldTooltip = ({ visible }: RequiredFieldTooltipProps) => {

    return (
        <>
            {visible &&
                <div id="event-search-form-required-input-keyword-tooltip-container">
                    <div className="div-text-center event-search-form-required-input-tooltip-chevron-container">
                        <div className="div-event-search-form-required-input-tooltip-chevron"></div>
                    </div>
                    <div className="event-search-form-required-input-tooltip">
                        <div className="row">
                            <div className="col-2">
                                <div className="event-search-form-required-input-tooltip-bang">
                                    <span>!</span>
                                </div>
                            </div>
                            <div className="col-10 d-flex align-items-center">
                                <span>Please fill out this field.</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default RequiredFieldTooltip;