export async function handleResponse(response: { ok: any; json: () => any; status: number; text: () => any; }) {
    if (response.ok) return response.json();
    if (response.status === 400) {
        const error = await response.text();
        throw new Error(error);
    }
    throw new Error("Network response was not ok.");
}

export function handleError(error: string) {
    console.error("API call failed. " + error);
    throw error;
}
