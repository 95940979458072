import * as actionTypes from "../actions/actionTypes";
import initialState from '../reducers/initialState';

export default function eventReducer(state = initialState.eventSearchResults, action: any) {
    switch (action.type) {
        case actionTypes.EXECUTE_KEYWORD_SUGGESTIONS_SUCCESS:
            return { ...state, eventSearchKeywordSuggestions: action.eventSearchKeywordSuggestions };
        case actionTypes.EXECUTE_EVENT_SEARCH_CLEAR_SUCCESS:
            return { ...state, eventSearchResults: action.eventSearchResults };
        case actionTypes.EXECUTE_GET_CRYPTO_BY_ID_SUCCESS:
            return { ...state, cryptoDetails: action.cryptoDetails };
        case actionTypes.EXECUTE_GET_TWEETS_SUCCESS:
            return { ...state, cryptoTweets: action.cryptoTweets };
        case actionTypes.EXECUTE_GET_EVENT_BY_ID_CLEAR_SUCCESS:
            return { ...state, eventDetails: action.eventDetails };
        case actionTypes.EXECUTE_GET_CRYPTO_ANALYSIS_SUCCESS:
            return { ...state, cryptoAnalysis: action.cryptoAnalysis };
        case actionTypes.EXECUTE_GET_CRYPTO_NEWS_SUCCESS:
            return { ...state, cryptoNews: action.cryptoNews };
        case actionTypes.EXECUTE_TOGGLE_FAVORITE_SUCCESS:
            return { ...state, eventFavorites: action.eventFavorites };
        case actionTypes.EXECUTE_LOAD_FAVORITES_SUCCESS:
            return { ...state, eventFavorites: action.eventFavorites };
        case actionTypes.EXECUTE_LOAD_CRYPTO_SUCCESS:
        case actionTypes.EXECUTE_SEARCH_CRYPTO_BY_PARAMS_SUCCESS:
            return { ...state, cryptoSearchResults: action.cryptoSearchResults };
        case actionTypes.EXECUTE_LOAD_ARTIST_DATA_FROM_SPOTIFY_SUCCESS:
            return { ...state, spotifyArtistData: action.spotifyArtistData };
        case actionTypes.EXECUTE_GET_COORDINATES_FROM_IP_SUCCESS:
            return { ...state, userCoordinates: action.userCoordinatesFromIp };
        case actionTypes.EXECUTE_GET_COORDINATES_FROM_LOCATION_TEXT_SUCCESS:
            return { ...state, userCoordinates: action.userCoordinatesFromLocationText };
        case actionTypes.EXECUTE_GET_COORDINATES_CLEAR_SUCCESS:
            return { ...state, userCoordinates: action.userCoordinates };
        case actionTypes.EXECUTE_LOGIN_USER_SUCCESS:
        case actionTypes.EXECUTE_LOGOUT_USER_SUCCESS:
            return { ...state, loginUserResult: action.loginUserResult };
        case actionTypes.EXECUTE_REGISTER_USER_SUCCESS:
        case actionTypes.EXECUTE_CLEAR_REGISTRATION_INFO:
            return { ...state, registerUserResult: action.registerUserResult };
        case actionTypes.EXECUTE_LOAD_CRYPTO_HISTORICAL_PRICES_SUCCESS:
            return { ...state, cryptoHistoricalPrices: action.cryptoHistoricalPrices };
        default:
            return state;
    }
}