import { CryptoSearchParams } from "../../types/userInput/CryptoSearchParams";
import { CoinMarketCapCryptoDataDto, CryptoSearchItem } from "../../types/reactOutput/CoinMarketCapCryptoDataDto";
import { CryptoDto } from "../../types/reactOutput/CryptoDto";
import { VenueDetailsCardDto } from "../../types/reactOutput/VenueDetailsCardDto";
import { GpsCoordinates } from "../../types/reactOutput/GpsCoordinates";
import * as cryptoApi from "../../api/cryptoApi";
import * as actionTypes from "./actionTypes"
import { CryptoSearchAutocompleteDto } from "../../types/reactOutput/CryptoSearchAutocompleteDto";
import { RegisterUserParams } from "../../types/userInput/RegisterUserParams";
import { LoginUserParams } from "../../types/userInput/LoginUserParams";
import { LoginUserResult } from "../../types/reactOutput/LoginUserResult";
import { RegisterUserResult } from "../../types/reactOutput/RegisterUserResult";
import { CryptoHistoricalPriceDto } from "../../types/reactOutput/CryptoHistoricalPriceDto";
import { CryptoAnalysisDto } from "../../types/reactOutput/CryptoAnalysisDto";
import { CryptoNewsDto } from "../../types/reactOutput/CryptoNewsDto";
import { TweetDto } from "../../types/reactOutput/TweetDto";

export function executeKeywordSuggestionsSuccess(autocompleteItems: CryptoSearchAutocompleteDto[]) {
    return { type: actionTypes.EXECUTE_KEYWORD_SUGGESTIONS_SUCCESS, eventSearchKeywordSuggestions: autocompleteItems };
}

export function executeKeywordSuggestions(keyword: string) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getKeywordSuggestions(keyword).then(keywordSuggestions => {
            dispatch(executeKeywordSuggestionsSuccess(keywordSuggestions));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeSearchCryptoByParamsSuccess(cryptoSearchResults: CryptoDto[]) {
    return { type: actionTypes.EXECUTE_SEARCH_CRYPTO_BY_PARAMS_SUCCESS, cryptoSearchResults: cryptoSearchResults };
}

export function executeSearchCryptoByParams(cryptoSearchParams: CryptoSearchParams) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCryptoByParams(cryptoSearchParams).then(cryptoSearchResults => {
            dispatch(executeSearchCryptoByParamsSuccess(cryptoSearchResults));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeLoginUserSuccess(loginUserResult: LoginUserResult) {    
    return { type: actionTypes.EXECUTE_LOGIN_USER_SUCCESS, loginUserResult: loginUserResult };
} 

export function executeLoginUser(loginUserParams: LoginUserParams){
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.loginUser(loginUserParams).then(loginUserResult => {
            localStorage.setItem('user', JSON.stringify(loginUserResult));
            dispatch(executeLoginUserSuccess(loginUserResult));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeFindUserDataInLocalStorage(){
    return function (dispatch: (arg0: any) => void) {
        let userFromLocalStorage = localStorage.getItem('user');
        let userJson = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : {}
        dispatch(executeLoginUserSuccess(userJson));
    }
}

export function executeLogoutUserSuccess() {
    return { type: actionTypes.EXECUTE_LOGOUT_USER_SUCCESS, loginUserResult: {} };
} 

export function executeLogoutUser(){
    return function (dispatch: (arg0: any) => void) {
        localStorage.setItem('user', JSON.stringify({}));
        dispatch(executeLogoutUserSuccess());
    }
}

export function executeClearRegistrationInfoSuccess() {
    return { type: actionTypes.EXECUTE_CLEAR_REGISTRATION_INFO, registerUserResult: {} };
} 

export function executeClearRegistrationInfo(){
    return function (dispatch: (arg0: any) => void) {
        dispatch(executeClearRegistrationInfoSuccess());
    }
}

export function executeRegisterUserSuccess(registerUserResult: RegisterUserResult) {
    return { type: actionTypes.EXECUTE_REGISTER_USER_SUCCESS, registerUserResult: registerUserResult };
} 

export function executeRegisterUser(registerUserParams: RegisterUserParams) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.registerUser(registerUserParams).then(registerUserResult => {
            dispatch(executeRegisterUserSuccess(registerUserResult));
        }).catch(error => {
            throw error;
        })
    }
} 

export function executeEventSearchClearSuccess() {
    return { type: actionTypes.EXECUTE_EVENT_SEARCH_CLEAR_SUCCESS, eventSearchResults: undefined };
}

export function executeEventSearchClear() {
    return function (dispatch: (arg0: any) => void) {
        dispatch(executeEventSearchClearSuccess());
    }
}

export function executeGetCryptoAnalysisSuccess(cryptoAnalysis: CryptoAnalysisDto) {
    return { type: actionTypes.EXECUTE_GET_CRYPTO_ANALYSIS_SUCCESS, cryptoAnalysis: cryptoAnalysis };
}

export function executeGetCryptoAnalysis(symbol: string) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCryptoAnalysis(symbol)
            .then(cryptoAnalysis => {
                dispatch(executeGetCryptoAnalysisSuccess(cryptoAnalysis));                
            }).catch(error => {
                throw error;
            })
    }
}

export function executeGetCryptoNewsSuccess(cryptoNews: CryptoNewsDto[]) {
    return { type: actionTypes.EXECUTE_GET_CRYPTO_NEWS_SUCCESS, cryptoNews: cryptoNews };
}

export function executeGetCryptoNews(symbol: string) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCryptoNews(symbol)
            .then(cryptoNews => {
                dispatch(executeGetCryptoNewsSuccess(cryptoNews));                
            }).catch(error => {
                throw error;
            })
    }
}

export function executeGetCryptoHistoricalPriceBySymbolSuccess(cryptoHistoricalPrices: CryptoHistoricalPriceDto[]) {
    return { type: actionTypes.EXECUTE_LOAD_CRYPTO_HISTORICAL_PRICES_SUCCESS, cryptoHistoricalPrices: cryptoHistoricalPrices };
}

export function executeGetCryptoHistoricalPriceBySymbol(symbol: string) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCryptoHistoricalPricesBySymbol(symbol)
            .then(prices => {
                dispatch(executeGetCryptoHistoricalPriceBySymbolSuccess(prices));                
            }).catch(error => {
                throw error;
            })
    }
}

export function executeGetTweetsSuccess(cryptoTweets: TweetDto[]) {
    return { type: actionTypes.EXECUTE_GET_TWEETS_SUCCESS, cryptoTweets: cryptoTweets };
}

export function executeGetTweets(symbol: string) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getTweets(symbol)
            .then(cryptoTweets => {
                dispatch(executeGetTweetsSuccess(cryptoTweets));
            }).catch(error => {
                throw error;
            })
    }
}

export function executeGetCryptoByIdSuccess(cryptoDto: CryptoDto) {
    return { type: actionTypes.EXECUTE_GET_CRYPTO_BY_ID_SUCCESS, cryptoDetails: cryptoDto };
}

export function executeGetCryptoById(cryptoId: number) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCryptoById(cryptoId)
            .then(cryptoDetails => {
                if(cryptoDetails.hasOwnProperty("data") && cryptoDetails.data.hasOwnProperty(cryptoId)){
                    dispatch(executeGetCryptoByIdSuccess(cryptoDetails.data[cryptoId]));
                }else {
                    dispatch(executeGetCryptoByIdSuccess(cryptoDetails));
                }
            }).catch(error => {
                throw error;
            })
    }
}

export function executeGetEventByIdClearSuccess() {
    return { type: actionTypes.EXECUTE_GET_EVENT_BY_ID_CLEAR_SUCCESS, eventDetails: undefined };
}

export function executeGetEventByIdClear() {
    return function (dispatch: (arg0: any) => void) {
        dispatch(executeGetEventByIdClearSuccess());
    }
}

export function executeToggleFavoriteSuccess(eventFavorites: CryptoDto[]) {
    return { type: actionTypes.EXECUTE_TOGGLE_FAVORITE_SUCCESS, eventFavorites: eventFavorites };
}

/*
If eventFavorites does not contain favoriteEvent then add favoriteEvent to eventFavorites. 
Else if eventFavorites contains favoriteEvent then remove favoriteEvent from eventFavorites. 
*/
export function executeToggleFavorite(favoriteEvent: CryptoDto, eventFavorites: CryptoDto[]) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.toggleFavorites(favoriteEvent, eventFavorites).then(newFavorites => {
            dispatch(executeToggleFavoriteSuccess(newFavorites));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeLoadFavoritesSuccess(eventFavorites: CryptoDto[]) {
    console.log(eventFavorites)
    return { type: actionTypes.EXECUTE_LOAD_FAVORITES_SUCCESS, eventFavorites: eventFavorites };
}

export function executeLoadFavorites() {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getFavorites().then(favorites => {
            dispatch(executeLoadFavoritesSuccess(favorites));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeCheckFavoritesSuccess(checkFlag: boolean) {
    return { type: actionTypes.EXECUTE_CHECK_FAVORITES_SUCCESS, checkFlag: checkFlag };
}

export function executeCheckFavorites(favoriteEvent: CryptoDto, eventFavorites: CryptoDto[]) {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.checkFavorites(favoriteEvent, eventFavorites).then(checkFlag => {
            dispatch(executeCheckFavoritesSuccess(checkFlag));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeLoadCryptoSuccess(cryptoSearchResults: CryptoDto[]) {
    return { type: actionTypes.EXECUTE_LOAD_CRYPTO_SUCCESS, cryptoSearchResults: cryptoSearchResults };
}

export function executeLoadCrypto() {
    return function (dispatch: (arg0: any) => void) {
        return cryptoApi.getCrypto().then(cryptoRawData => {
            let crypto: CryptoDto[] = []

            if (typeof(cryptoRawData) !== 'undefined' 
            && typeof(cryptoRawData.data) !== 'undefined' 
            && cryptoRawData.data.length > 0)
                crypto = cryptoRawData.data.map((c: CryptoSearchItem) => {
                    return { 
                        id: c.id,
                        name: c.name,
                        symbol: c.symbol,
                        price: c.quote.USD.price,
                        market_cap: c.quote.USD.market_cap,
                        volume_24h: c.quote.USD.volume_24h,
                        circulating_supply: c.circulating_supply                        
                     }
                })

            dispatch(executeLoadCryptoSuccess(crypto));
        }).catch(error => {
            throw error;
        })
    }
}

export function executeGetCoordinatesFromLocationTextSuccess(userCoordinatesFromLocationText: GpsCoordinates) {
    return { type: actionTypes.EXECUTE_GET_COORDINATES_FROM_LOCATION_TEXT_SUCCESS, userCoordinatesFromLocationText: userCoordinatesFromLocationText };
}

export function executeGetCoordinatesFromIpSuccess(userCoordinatesFromIp: GpsCoordinates) {
    return { type: actionTypes.EXECUTE_GET_COORDINATES_FROM_IP_SUCCESS, userCoordinatesFromIp: userCoordinatesFromIp };
}

export function executeGetCoordinatesClearSuccess() {
    return { type: actionTypes.EXECUTE_GET_COORDINATES_CLEAR_SUCCESS, userCoordinates: undefined };
}

export function executeNewsClearSuccess() {
    return { type: actionTypes.EXECUTE_GET_CRYPTO_NEWS_SUCCESS, cryptoNews: undefined };
}

export function executeTweetsClearSuccess() {
    return { type: actionTypes.EXECUTE_GET_TWEETS_SUCCESS, cryptoTweets: undefined };
}

export function executeGetCoordinatesClear() {
    return function (dispatch: (arg0: any) => void) {
        dispatch(executeGetCoordinatesClearSuccess());
    }
}