import React from 'react';
import Plot from 'react-plotly.js';
import { PriceChartProps } from '../../types/props/PriceChartProps';

const PriceChart = ({priceChartProps} : {priceChartProps:PriceChartProps}) => { 
    return (
        <Plot
          data={[
            {
              x: priceChartProps.dataPoints.map(d => d.date),
              y: priceChartProps.dataPoints.map(d => d.price),
              type: 'scatter',
              mode: 'lines',
            },
          ]}
          layout={{
            autosize: false,
            paper_bgcolor: '#222', // Dark background color
            plot_bgcolor: '#222', // Dark plot area color
            font: {
                color: '#fff', // White text color
            },
            //paper_bgcolor: 'rgba(255,255,255, 0)',
            //margin: { t: 0, b: 0, l: 0, r: 0 },
            title: 'Historical Price',
            xaxis: { title: 'Date', gridcolor: '#555' },
            yaxis: { title: 'Price', gridcolor: '#555' },
          }}
        />
      );
}

export default PriceChart;