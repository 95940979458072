import React from 'react';
import { CryptoDto } from '../../types/reactOutput/CryptoDto';
import { VenueDetailsCardDto } from '../../types/reactOutput/VenueDetailsCardDto';
import CryptoDetailsCardNavigation from './CryptoDetailsCardNavigation';
import { CryptoHistoricalPriceDto } from '../../types/reactOutput/CryptoHistoricalPriceDto';
import { LoginUserResult } from '../../types/reactOutput/LoginUserResult';
import { CryptoAnalysisDto } from '../../types/reactOutput/CryptoAnalysisDto';
import { CryptoNewsDto } from '../../types/reactOutput/CryptoNewsDto';
import { TweetDto } from '../../types/reactOutput/TweetDto';

type CryptoDetailsCardProps = {
    cryptoDetails: CryptoDto,
    cryptoAnalysis: CryptoAnalysisDto,
    cryptoNews: CryptoNewsDto[]
    loginUserResult: LoginUserResult,
    cryptoHistoricalPrices: CryptoHistoricalPriceDto[]
    eventFavorites: CryptoDto[],
    cryptoTweets: TweetDto[],
    favoritesToggle: (cryptoDto: CryptoDto) => void;
    onBackClicked: () => void;
}

const CryptoDetailsCard = ({
    cryptoDetails,
    cryptoAnalysis,
    cryptoNews,
    loginUserResult,
    cryptoHistoricalPrices,
    eventFavorites,
    cryptoTweets,
    favoritesToggle,
    onBackClicked }: CryptoDetailsCardProps) => {

    const onFavoritesButtonClicked = (e: { preventDefault: () => void; }, isFavorite: boolean) => {
        e.preventDefault();
        favoritesToggle(cryptoDetails)
        if (isFavorite) {
            alert("Removed from Favorites!");            
        } else {
            alert("Crypto Added to Favorites!");
        }        
    }

    return (
        <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-10">
                {cryptoDetails &&
                    <div className="card text-white frosted-background mb-3">
                        <div className="card-body">
                            <div>
                                <span className="material-icons">
                                    chevron_left
                                </span>
                                <span className="align-top underline-text cursor-pointer" onClick={onBackClicked}>Back</span>
                            </div>
                            <div className="row pb-4">
                                <div className="col-lg-12 text-center">
                                    <span className="span-event-details-card-title">
                                        {cryptoDetails.name}
                                    </span>
                                    <span className="ms-3 md-24">
                                        <img src={cryptoDetails.logo}
                                        className="img-thumbnail crypto-detail-logo" 
                                        alt="Logo"></img> 
                                    </span>
                                    {
                                        loginUserResult && loginUserResult.email ?
                                            eventFavorites && Array.isArray(eventFavorites) && eventFavorites.some(favorite => favorite.symbol == cryptoDetails.symbol) ? 
                                            <span onClick={(e) => onFavoritesButtonClicked(e, true)} className="material-icons ms-3 md-24 p-2 text-danger rounded-circle bg-light">favorite</span>
                                            : <span onClick={(e) => onFavoritesButtonClicked(e, false)} className="material-icons ms-3 md-24 p-2 text-dark rounded-circle bg-light">favorite_border</span>
                                        : ''
                                    }
                                </div>
                            </div>
                            <CryptoDetailsCardNavigation
                                cryptoDetails={cryptoDetails}
                                cryptoAnalysis={cryptoAnalysis}
                                cryptoNews={cryptoNews}
                                cryptoTweets={cryptoTweets}
                                cryptoHistoricalPrices={cryptoHistoricalPrices}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CryptoDetailsCard;