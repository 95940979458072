import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './App.css';
import * as cryptoSearchActions from './redux/actions/cryptoSearchActions';
import LoginPage from './components/login/LoginPage';
import RegisterPage from './components/login/RegisterPage';
import FavoritesPage from './components/favorites/FavoritesPage';
import MainMenuToggle from './components/common/MainMenuToggle';
import CryptoLandingPage from './components/search/CryptoLandingPage';
import PageNotFound from './components/common/PageNotFound';
import { LoginUserResult } from './types/reactOutput/LoginUserResult';
import { CryptoDto } from './types/reactOutput/CryptoDto';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';

TimeAgo.addDefaultLocale(en)

const App  = ({
    loginUserResult,
    eventFavorites,
    actions }: { loginUserResult: LoginUserResult, eventFavorites: CryptoDto[], actions: any }) => {
        
    useEffect(() => {
        document.title = 'Crypto Buddy';
    }, []);

    useEffect(() => {
        if(!loginUserResult){
            actions.executeFindUserDataInLocalStorage();
        }
    }, [loginUserResult])

    useEffect(() => {
        if (!eventFavorites) {
            actions.executeLoadFavorites()
                .catch((error: any) => {
                    console.log("Error Executing Get Event By Id: " + error)
                });
        }
        console.log(eventFavorites);
    }, [eventFavorites])

    const AddLibrary = (urlOfTheLibrary: string) => {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }

    return (
        <BrowserRouter>
            <div className="App">
                <>
                    <div className="row">
                        <div className="col-lg-12">
                            <MainMenuToggle />
                        </div>
                    </div>
                    <Routes>
                        <Route path="/" element={<CryptoLandingPage />}></Route>
                        <Route path="/search" element={<CryptoLandingPage />}></Route>
                        <Route path="/favorites" element={<FavoritesPage />}></Route>
                        <Route path="/login" element={<LoginPage />}></Route>
                        <Route path="/register" element={<RegisterPage />}></Route>
                        <Route path="*" element={<PageNotFound />}></Route>
                    </Routes>
                    {AddLibrary('https://kit.fontawesome.com/60828a0c18.js')}
                </>
            </div>
        </BrowserRouter>
  );
}

function mapStateToProps(state: any) {
    return {
        loginUserResult: state.eventReducer.loginUserResult,
        eventFavorites: state.eventReducer.eventFavorites
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        actions: bindActionCreators(cryptoSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
