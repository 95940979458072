import React, { useState } from 'react';
import { CryptoDetailsCardProps } from '../../../types/props/CryptoDetailsCardProps';
import StickyHeaderScrollableTable from '../../common/StickyHeaderScrollableTable';
import { MaterialUITableColumn } from '../../../types/reactOutput/MaterialUITableColumn';
import { MaterialUITableRow } from '../../../types/reactOutput/MaterialUITableRow';

const CryptoHistoricalPricingTable = ({ cryptoHistoricalPrices, cryptoAnalysis, cryptoDetails }: CryptoDetailsCardProps) => {

    const columns: MaterialUITableColumn[] = [
        { id: 'name', label: 'Name', minWidth: 170, align: 'center',
            format: (value: string) => value},
        { id: 'symbol', label: 'Symbol', minWidth: 100, align: 'center',
            format: (value: string) => value},
        { id: 'date', label: 'Date', minWidth: 170, align:'center',
            format: (value: string) => new Intl.DateTimeFormat('en-US', {
                dateStyle: 'short',
                timeStyle: 'long',
                //timeZone: 'UTC'
              }).format(new Date(value))},
        { id: 'price', label: 'Price in USD', minWidth: 170, align:'center',
            format: (value: number) => new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value)},
      ];


      const rows : MaterialUITableRow[] = cryptoHistoricalPrices.map(history => {
        return { 
            id: history.id, 
            name: history.name, 
            symbol: history.symbol, 
            date: history.updated_at, 
            price: history.price  }
      })

    return (
        <>
        <div className='row mt-4'>
            <div className='col-lg-12'>
                <StickyHeaderScrollableTable columns={columns} rows={rows}/>
            </div>
        </div>         
        </>
    );
}

export default CryptoHistoricalPricingTable;
