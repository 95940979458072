import React from 'react';
import { CryptoDetailsCardProps } from '../../../types/props/CryptoDetailsCardProps';
import ShowMoreText from "react-show-more-text";
import PriceChart from '../../common/PriceChart';
import { PriceChartProps } from '../../../types/props/PriceChartProps';
//https://www.npmjs.com/package/react-circular-progressbar
//https://codesandbox.io/s/vymm4oln6y?file=/index.js
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

const CryptoInfo = ({ cryptoDetails, cryptoAnalysis, cryptoHistoricalPrices }: CryptoDetailsCardProps) => {
    const showMoreShowLessLines = 2
    const data: PriceChartProps = {
        dataPoints: []
      };

      data.dataPoints = cryptoHistoricalPrices.map(history => {
        return { date: history.updated_at, price: history.price }
      })

      console.log(data.dataPoints);
        

    return (
        <>
            <div className="row d-flex mt-4">
                <div className="col-lg-5 text-center">
                    <div className="row">
                        <div className="col-lg-4">
                        {cryptoDetails.name && <>                            
                            <span className="span-event-details-label">{cryptoDetails.name}</span>
                            <span>Name</span>
                        </>}
                        </div>
                        <div className="col-lg-4">
                        {cryptoDetails.symbol && <>                            
                            <span className="span-event-details-label">{cryptoDetails.symbol}</span>
                            <span>Symbol</span>
                        </>}
                        </div>
                        <div className="col-lg-4">
                        {cryptoDetails.slug && <>
                            <span className="span-event-details-label">{cryptoDetails.slug}</span>
                            <span>Slug</span>                            
                        </>}
                        </div>
                    </div>
                    <div className="row" >
                        {cryptoAnalysis && cryptoAnalysis.popularity && 
                        <div className="col-lg-6">
                            <span className="span-event-details-label">Popularity</span>
                            <div className="row justify-content-center">
                                    <div className="col-4 pt-1" style={{ width: 75, height: 75 }}>
                                        {cryptoAnalysis.popularity}
                                    </div>
                            </div>
                        </div>}
                        {cryptoAnalysis && cryptoAnalysis.sentiment && 
                        <div className="col-lg-6">
                            <span className="span-event-details-label">Sentiment</span>
                            <div className="row justify-content-center">
                                    <div className="col-4 pt-1" style={{ width: 75, height: 75 }}>
                                        <CircularProgressbar
                                            styles={buildStyles({
                                                backgroundColor: "#3e98c7",
                                                textColor: "#ffffff",
                                                textSize: "25px",
                                                pathColor: "#16DB65",
                                                trailColor: "#222222",
                                                strokeLinecap: "butt",                                                    
                                                pathTransitionDuration: 0.5,
                                            })} 
                                            minValue={-1}
                                            maxValue={1}
                                            strokeWidth={15} 
                                            value={cryptoAnalysis.sentiment}
                                            text={`${cryptoAnalysis.sentiment.toFixed(3)}`} />
                                    </div>
                            </div>
                        </div>}
                    </div>
                    {cryptoDetails.description && <>
                        <span className="span-event-details-label">Description</span>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 text-center">                                
                                <ShowMoreText
                                    lines={showMoreShowLessLines}
                                    more={<> <span className="display-block"><span className="show-more-less-clickable">Show More</span><span className="material-icons align-bottom">expand_more</span></span></>}
                                    less={<> <span className="display-block"><span className="show-more-less-clickable">Show less</span><span className="material-icons align-bottom">expand_less</span></span></>}
                                    className="content-css"
                                    anchorClass=""
                                    expanded={false}
                                    truncatedEndingComponent={''}
                                >
                                    {cryptoDetails.description}
                                </ShowMoreText>
                            </div>
                        </div>
                    </>}
                    {cryptoDetails.date_launched && <>
                        <span className="span-event-details-label">Date Launched</span>
                        <span>{new Date(cryptoDetails.date_launched).toLocaleDateString('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                        })}
                        </span>
                    </>}
                    {cryptoDetails.urls && cryptoDetails.urls.source_code && cryptoDetails.urls.source_code.length > 0 && <>
                        <span className="span-event-details-label">Source Code</span>
                        <a target="_blank" href={cryptoDetails.urls.source_code[0]}>{cryptoDetails.urls.source_code[0]}</a>
                    </>}
                    {cryptoDetails.urls && cryptoDetails.urls.technical_doc && cryptoDetails.urls.technical_doc.length > 0 && <>
                        <span className="span-event-details-label">Techincal Doc</span>
                        <a target="_blank" href={cryptoDetails.urls.technical_doc[0]}>{cryptoDetails.urls.technical_doc[0]}</a>
                    </>}
                    {cryptoDetails.tags && cryptoDetails.tags.length > 5 && 
                        <span className="span-event-details-label">Tags</span>
                    }
                    {cryptoDetails.tags && cryptoDetails.tags.length > 5 && cryptoDetails.tags.slice(0, 5).map(tag => (
                                <div className="badge bg-success me-1"><span>{tag}</span></div>))
                    }
                </div>
                <div className="col-lg-6 mt-4">
                    <PriceChart priceChartProps={data} />
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-lg-12">
                    <span className="d-flex align-items-center justify-content-center">
                        Find on:&nbsp;&nbsp;&nbsp;
                        <a target="_blank"
                            href={cryptoDetails.urls && cryptoDetails.urls.website && cryptoDetails.urls.website.length > 0 ? cryptoDetails.urls.website[0] : ''}
                            className="fa-solid fa-globe fa-2x website-icon-blue no-text-decoration "
                            aria-hidden="true">
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a target="_blank"
                            href={cryptoDetails.urls && cryptoDetails.urls.reddit && cryptoDetails.urls.reddit.length > 0 ? cryptoDetails.urls.reddit[0] : ''}
                            className="fa-brands fa-reddit fa-2x reddit-red no-text-decoration"
                            aria-hidden="true">
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
}

export default CryptoInfo;